var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.showTemplate === 0,
          expression: "showTemplate === 0",
        },
      ],
      staticClass: "manager-container",
    },
    [
      _vm.showTemplate === 2
        ? _c("edit-automatic-schedule", {
            attrs: {
              "job-data": _vm.currentContract,
              mpaTransactionData: _vm.mpaTransactionData,
              hasSysAdminPermission: _vm.hasSysAdminPermission,
              locationOptionProps: _vm.locationOptionProps,
              availableBargesOptions: _vm.availableBargesOptions,
              availableBargesLoading: _vm.availableBargesLoading,
              sgTradexState: _vm.sgTradexState,
            },
            on: {
              close: _vm.closeDetail,
              checkAvailableBargesOptions: _vm.visibleChange,
              "update:sgTradexState": function ($event) {
                _vm.sgTradexState = $event
              },
              "update:sg-tradex-state": function ($event) {
                _vm.sgTradexState = $event
              },
              onClearInterval: _vm.onClearInterval,
            },
          })
        : _vm._e(),
      _vm.showTemplate === "loading"
        ? _c("handle-exwharf-schedule", {
            attrs: {
              "job-data": _vm.currentContract,
              mpaTransactionData: _vm.mpaTransactionData,
              currentCompanyShips: _vm.currentCompanyShips,
              locationOptionProps: _vm.locationOptionProps,
            },
            on: {
              close: _vm.closeDetail,
              "refresh-company-ships": _vm.getShipsByCompany,
            },
          })
        : _vm._e(),
      _vm.showTemplate === "delivery"
        ? _c("handle-delivered-schedule", {
            attrs: {
              "job-data": _vm.currentContract,
              mpaTransactionData: _vm.mpaTransactionData,
              hasSysAdminPermission: _vm.hasSysAdminPermission,
              locationOptionProps: _vm.locationOptionProps,
              sgTradexState: _vm.sgTradexState,
            },
            on: {
              "update:sgTradexState": function ($event) {
                _vm.sgTradexState = $event
              },
              "update:sg-tradex-state": function ($event) {
                _vm.sgTradexState = $event
              },
              close: _vm.closeDetail,
              onClearInterval: _vm.onClearInterval,
            },
          })
        : _vm._e(),
      _vm.showTemplate === "sts"
        ? _c("handle-internal-transfer", {
            attrs: {
              "job-data": _vm.currentContract,
              mpaTransactionData: _vm.mpaTransactionData,
              hasSysAdminPermission: _vm.hasSysAdminPermission,
              locationOptionProps: _vm.locationOptionProps,
              sgTradexState: _vm.sgTradexState,
              currentCompanyShips: _vm.currentCompanyShips,
            },
            on: {
              close: _vm.closeDetail,
              "update:sgTradexState": function ($event) {
                _vm.sgTradexState = $event
              },
              "update:sg-tradex-state": function ($event) {
                _vm.sgTradexState = $event
              },
              onClearInterval: _vm.onClearInterval,
              "refresh-company-ships": _vm.getShipsByCompany,
            },
          })
        : _vm._e(),
      _vm.showTemplate === 1
        ? [
            _c("div", { staticClass: "flex-between-row" }, [
              _c("h1", { staticClass: "div-main-view-title" }, [
                _vm._v(_vm._s(_vm.orderType)),
              ]),
            ]),
            _c(
              "el-row",
              {
                class: _vm.isFilterVisit
                  ? " shadow-border-bottom handler-bar"
                  : "handler-bar",
                attrs: { gutter: 25, align: "middle" },
              },
              [
                _c(
                  "el-col",
                  { attrs: { span: _vm.isMobileScreen ? 24 : 12 } },
                  [
                    _c(
                      "el-tabs",
                      {
                        on: {
                          "tab-click": function ($event) {
                            return _vm.getJobTableData()
                          },
                        },
                        model: {
                          value: _vm.activeName,
                          callback: function ($$v) {
                            _vm.activeName = $$v
                          },
                          expression: "activeName",
                        },
                      },
                      _vm._l(_vm.statusTabs, function (item, index) {
                        return _c("el-tab-pane", {
                          key: item.name,
                          attrs: { label: item.name, name: index.toString() },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: _vm.isMobileScreen ? 24 : 12 } },
                  [
                    _c("div", { staticClass: "button-block" }, [
                      _c("div", { staticClass: "query-item filter-btn" }, [
                        _c(
                          "span",
                          {
                            class: !_vm.isFilterVisit
                              ? "span-btn-primay"
                              : "span-btn-active",
                            on: {
                              click: function ($event) {
                                _vm.isFilterVisit = !_vm.isFilterVisit
                              },
                            },
                          },
                          [_vm._v(" Filter ")]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "query-item search-bar" },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "round-input",
                              attrs: {
                                size: _vm.isMobileScreen ? "mini" : "default",
                                placeholder:
                                  "Search By Job id, Barge, Vessel or Terminal",
                              },
                              nativeOn: {
                                keypress: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.getJobTableData()
                                },
                              },
                              model: {
                                value: _vm.jobSearchVal,
                                callback: function ($$v) {
                                  _vm.jobSearchVal = $$v
                                },
                                expression: "jobSearchVal",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  type: "primary",
                                  icon: "el-icon-search",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getJobTableData()
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "query-item create-btn" },
                        [
                          _vm.hasPermission(_vm.$permissionFunctionCode.ADD)
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: _vm.isMobileScreen
                                      ? "mini"
                                      : "default",
                                    round: "",
                                    type: "primary",
                                    loading: _vm.createNewScheduleLoading,
                                  },
                                  on: { click: _vm.createNewSchedule },
                                },
                                [
                                  _vm.isMobileScreen
                                    ? _c("span", [_vm._v("+")])
                                    : _c("span", [
                                        _vm._v(_vm._s(_vm.$t("Create Job"))),
                                      ]),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: _vm.isMobileScreen ? "mini" : "default",
                                round: "",
                                type: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.uploadJobDialogVisible = true
                                },
                              },
                            },
                            [_c("span", { staticClass: "el-icon-upload2" })]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: _vm.isMobileScreen ? "mini" : "default",
                                round: "",
                                type: "primary",
                              },
                              on: { click: _vm.handleDownloadJob },
                            },
                            [_c("span", { staticClass: "el-icon-download" })]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm.isFilterVisit
              ? _c(
                  "el-row",
                  {
                    staticClass: "handler-bar",
                    staticStyle: { margin: "5px 0" },
                    attrs: { gutter: 5, align: "middle" },
                  },
                  [
                    _c(
                      "el-col",
                      {
                        class: {
                          "filter-line-mobile": _vm.isMobileScreen,
                          "filter-line": !_vm.isMobileScreen,
                        },
                        attrs: { span: 20 },
                      },
                      [
                        [
                          _vm.isMobileScreen
                            ? _c("span", { staticClass: "label-mobile" }, [
                                _vm._v("Job Type:"),
                              ])
                            : _vm._e(),
                          _c(
                            "el-select",
                            {
                              attrs: {
                                size: _vm.isMobileScreen ? "mini" : "small",
                                placeholder: "Select a Job Type",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getJobTableData()
                                },
                              },
                              model: {
                                value: _vm.jobFilter.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.jobFilter, "type", $$v)
                                },
                                expression: "jobFilter.type",
                              },
                            },
                            _vm._l(_vm.jobOpts, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        [
                          _vm.isMobileScreen
                            ? _c("span", { staticClass: "label-mobile" }, [
                                _vm._v("Contract Type:"),
                              ])
                            : _vm._e(),
                          _c(
                            "el-select",
                            {
                              attrs: {
                                size: _vm.isMobileScreen ? "mini" : "small",
                                placeholder: "Select a Contract Type",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getJobTableData()
                                },
                              },
                              model: {
                                value: _vm.jobFilter.termContractType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.jobFilter,
                                    "termContractType",
                                    $$v
                                  )
                                },
                                expression: "jobFilter.termContractType",
                              },
                            },
                            _vm._l(_vm.termContractTypeOption, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        [
                          _vm.isMobileScreen
                            ? _c("span", { staticClass: "label-mobile" }, [
                                _vm._v("Ship:"),
                              ])
                            : _vm._e(),
                          _c(
                            "el-select",
                            {
                              staticClass: "vessel-selector",
                              attrs: {
                                size: _vm.isMobileScreen ? "mini" : "small",
                                placeholder: "Select a Ship",
                              },
                              on: { change: _vm.handleSelectFilterShip },
                              model: {
                                value: _vm.jobFilter.bargeShipId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.jobFilter, "bargeShipId", $$v)
                                },
                                expression: "jobFilter.bargeShipId",
                              },
                            },
                            _vm._l(_vm.shipOptsByCompany, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        [
                          _vm.isMobileScreen
                            ? _c("span", { staticClass: "label-mobile" }, [
                                _vm._v("Company:"),
                              ])
                            : _vm._e(),
                          _c(
                            "el-select",
                            {
                              staticClass: "vessel-selector",
                              attrs: {
                                size: _vm.isMobileScreen ? "mini" : "small",
                                placeholder: "Select a Company",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getJobTableData()
                                },
                              },
                              model: {
                                value: _vm.jobFilter.companyId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.jobFilter, "companyId", $$v)
                                },
                                expression: "jobFilter.companyId",
                              },
                            },
                            _vm._l(_vm.companyFiltersOptions, function (group) {
                              return _c(
                                "el-option-group",
                                {
                                  key: group.label,
                                  attrs: { label: group.label },
                                },
                                _vm._l(group.options, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        [
                          _vm.isMobileScreen
                            ? _c("span", { staticClass: "label-mobile" }, [
                                _vm._v("Date Range:"),
                              ])
                            : _vm._e(),
                          _c(
                            "span",
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  size: _vm.isMobileScreen ? "mini" : "small",
                                  type: "datetimerange",
                                  "range-separator": "to",
                                  "start-placeholder": "ETA Start Date",
                                  "end-placeholder": "ETA End Date",
                                  "popper-class": "custom-datetimerange",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.getJobTableData()
                                  },
                                },
                                model: {
                                  value: _vm.jobFilter.date,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.jobFilter, "date", $$v)
                                  },
                                  expression: "jobFilter.date",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                      ],
                      2
                    ),
                    _c("el-col", { attrs: { span: 4 } }, [
                      _c(
                        "span",
                        {
                          staticClass: "span-btn-primay",
                          staticStyle: { float: "right", width: "50px" },
                          on: {
                            click: function ($event) {
                              return _vm.handleResetSearchQuery()
                            },
                          },
                        },
                        [_vm._v(" Reset ")]
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "table-block margin-top-0",
                style: {
                  height: _vm.isFilterVisit
                    ? "calc(100% - 150px)"
                    : "calc(100% - 74px)",
                },
              },
              [
                _c("table-job", {
                  attrs: {
                    hasTableMenu: "",
                    hasPagination: "",
                    isJobTableLoading: _vm.isJobTableLoading,
                    availableBargesLoading: _vm.availableBargesLoading,
                    availableBargesOptions: _vm.availableBargesOptions,
                    jobTableData: _vm.jobTableData,
                    activeTab: +_vm.activeName,
                    jobPagination: _vm.jobPagination,
                  },
                  on: {
                    handleJobDropdownMenuClick: _vm.handleJobDropdownMenuClick,
                    handleJobPageChange: _vm.handleJobPageChange,
                    handleJobPageSizeChange: _vm.handleJobPageSizeChange,
                    checkAvailableBargesOptions: _vm.visibleChange,
                    handleJobTableRefresh: _vm.handleJobTableRefresh,
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.contractTypeVisible
        ? _c("select-schedule-type", {
            on: {
              closed: function ($event) {
                _vm.contractTypeVisible = false
              },
              selected: _vm.selectedHandler,
            },
          })
        : _vm._e(),
      _vm.locationDialogVisible
        ? _c("add-terminal-and-berth", {
            on: {
              closed: function ($event) {
                _vm.locationDialogVisible = false
              },
            },
          })
        : _vm._e(),
      _c("file-preview-list", {
        attrs: { visible: _vm.attachmentsVisible, order: _vm.currentContract },
        on: {
          "update:visible": function ($event) {
            _vm.attachmentsVisible = $event
          },
        },
      }),
      _vm.blockchainInfoVisible
        ? _c("dialog-blockchain-info", {
            attrs: { propsData: _vm.blockchainData },
            on: { onClose: _vm.handleCloseBlockchainInfo },
          })
        : _vm._e(),
      _c("dialog-transfer-job", {
        attrs: {
          dialogTransferJob: _vm.dialogTransferJob,
          "job-data": _vm.currentContract,
          availableBargesLoading: _vm.availableBargesLoading,
          availableBargesOptions: _vm.availableBargesOptions,
        },
        on: {
          checkAvailableBargesOptions: _vm.visibleChange,
          handleTransferJobsDialogClose: function ($event) {
            _vm.dialogTransferJob = false
          },
          handleJobTableRefresh: _vm.handleJobTableRefresh,
        },
      }),
      _vm.addRecipientmailFormDialogVisible
        ? _c("AddRecipientEmail", {
            attrs: { entity: _vm.currentContract },
            on: {
              close: () => (_vm.addRecipientmailFormDialogVisible = false),
            },
          })
        : _vm._e(),
      _vm.uploadJobDialogVisible
        ? _c("UploadJob", {
            on: {
              success: () => _vm.getJobTableData(),
              close: () => (_vm.uploadJobDialogVisible = false),
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }